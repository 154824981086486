import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as GrIcons from "react-icons/gr";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const travelduqaIcon = require("../../../assets/images/travel-duga-logo.png");
const sucessIcon = require("../../../assets/images/success.png");
const poweredBy = require("../../../assets/images/powered-by.png");

function ClaimConfirmation({ setCurrentScreen }: any) {
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentScreen("claim-confirmation");
    sessionStorage.setItem("claim-screens", "claim-confirmation");
  });

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={travelduqaIcon} alt="travelduqa icon" />
        </div>
        <div className="reg-title text-center">Travel Insurance</div>
        <div className="confirmation">
          <div className="confirmation-icon">
            <img src={sucessIcon} alt="success icon" />
          </div>
          <div className="confirmation-title">Claim Submitted Successfully</div>
          <div className="confirmation-content">
            Your claim is being processed. We will contact you to explain the
            next steps to be followed
          </div>
          <div className="confirmation-btn">
            <button
              className="pointer-btn"
              onClick={() => {
                sessionStorage.removeItem("claim-screens");
                sessionStorage.removeItem("baggageItems");
                navigate("/list-claims");
              }}
            >
              VIEW MY CLAIM
            </button>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-5">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.setItem("claim-screens", "make-claim");
                setCurrentScreen("make-claim");
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <div className="">
            <a href="https://bit.ly/3FprVBJ" target="_blank" rel="noreferrer">
              <img src={poweredBy} />
            </a>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default ClaimConfirmation;
