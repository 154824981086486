import React, { useState } from "react";
import { motion } from "framer-motion";
import "./steps.css";
import { useWizard } from "react-use-wizard";
import { useDispatch } from "react-redux";
import { stepActions } from "../../store/Steps";

let TravelDugaLogo = require("../../../assets/images/travel-duga-logo.png");
let playstoreMtek = require("../../../assets/images/playstore-mtek.png");
let playstore = require("../../../assets/images/playstore.png");
let MtekLogo = require("../../../assets/images/mtek-logo.png");
let user2 = require("../../../assets/images/user2.png");
let arrowDown = require("../../../assets/images/down-arrow.png");

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};
function Steps({ name }: any) {
  const { goToStep } = useWizard();
  const [toggle, setToggle] = useState<any>(false);
  const dispatch = useDispatch();
  const displayEmail = localStorage.getItem("email")!;
  const userEmail = sessionStorage.getItem("email")!;
  const displayName = localStorage.getItem("userName")!;
  const username = sessionStorage.getItem("userName")!;
  const dname = displayName ? displayName : username;
  const email = displayEmail ? displayEmail : userEmail;

  const onSignOut = () => {
    sessionStorage.removeItem("userName");
    sessionStorage.clear();
    localStorage.clear();
    dispatch(stepActions.onRestartCurrentStepToDefault()) && goToStep(0);
    window.location.reload();
  };

  const getCurrentStepOnMobile = (stepName: string): string => {
    let step = "";
    switch (stepName) {
      case "get-started":
        step = "Step 1/9";
        return step;
      case "travel-details":
        step = "step 2/9";
        return step;
      case "quotes":
        step = "step 3/9";
        return step;
      case "auth":
        step = "step 4/9";
        return step;
      case "prepare":
        step = "step 5/9";
        return step;
      case "insured-details":
        step = "step 6/9";
        return step;
      case "beneficiary":
        step = "step 7/9";
        return step;
      case "group-details":
        step = "step 7/9";
        return step;
      case "summary":
        step = "step 8/9";
        return step;
      case "payment":
        step = "step 9/9";
        return step;
    }
    return step;
  };
  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      className="steps-container step-1-container"
    >
      <div
        className="desktop-brand image"
        onClick={() => {
          dispatch(stepActions.onRestartCurrentStepToDefault());
          sessionStorage.removeItem("payment-screen")
          goToStep(0);
        }}
      >
        <div>
          <img src={TravelDugaLogo} alt="cheki logo"></img>
        </div>
      </div>

      <div className="desktop-brand-mtek">
        <a href="https://bit.ly/3FprVBJ" target="_blank" rel="noreferrer">
          <div className="brand-container brand-step1">
            <h2 className="brand-text">
              <img
                className="brand-image"
                src={playstoreMtek}
                alt="mtek logo"
              ></img>
            </h2>
            <img className="brand-image" src={playstore} alt="mtek logo"></img>
          </div>
        </a>

        {dname && (
          <div className="auth-mgt-wrapper mt-3">
            <div onClick={() => setToggle(!toggle)} className="auth-mgmt-btn-k">
              <img width={25} src={user2} alt="user" />
              <span className="ms-1 me-1">{dname || ""}</span>
              <img width={25} src={arrowDown} alt="down arrow" />
            </div>
            {toggle && (
              <div className="text-center">
                <p className="emial-p mt-2">{email || ""}</p>
                <div className="sign-out-btn" onClick={() => onSignOut()}>
                  SIGN OUT
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="mob-logos-container">
        <div
          onClick={() => {
            dispatch(stepActions.onRestartCurrentStepToDefault());
            sessionStorage.removeItem("payment-screen")
            goToStep(0);
          }}
        >
          <img src={TravelDugaLogo} alt="cheki logo"></img>
        </div>
        <br />
        <div className="">
          <a href="https://bit.ly/3FprVBJ" target="_blank" rel="noreferrer">
            <div className="brand-container brand-step1">
              <h2 className="brand-text">
                <img
                  className="brand-image"
                  src={playstore}
                  alt="mtek logo"
                ></img>
              </h2>
              <img className="brand-image" src={MtekLogo} alt="mtek logo"></img>
            </div>
          </a>
        </div>
      </div>
      <p className="step-indicator step-1">{getCurrentStepOnMobile(name)}</p>
      <div className="desktop-nav">
        <div className="nav-wrapper">
          <div
            className={
              name == "get-started"
                ? "nav-step-container marked"
                : "nav-step-container"
            }
          >
            <div
              className={
                name == "get-started" ? "nav-circle active-grow" : "nav-circle"
              }
            >
              <span>1</span>
            </div>
            <br />
            <span className="step-details">
              {name == "get-started" ? "Get Started" : ""}
            </span>
          </div>
          <div
            className={
              name == "travel-details"
                ? "nav-step-container marked"
                : "nav-step-container"
            }
          >
            <div
              className={
                name == "travel-details"
                  ? "nav-circle active-grow"
                  : "nav-circle"
              }
            >
              <span>2</span>
            </div>
            <br />
            <span className="step-details">
              {name == "travel-details" ? "Travel Details" : ""}
            </span>
          </div>
          <div
            className={
              name == "quotes"
                ? "nav-step-container marked"
                : "nav-step-container"
            }
          >
            <div
              className={
                name == "quotes" ? "nav-circle active-grow" : "nav-circle"
              }
            >
              <span>3</span>
            </div>
            <br />
            <span className="step-details">
              {name == "quotes" ? "Quotes" : ""}
            </span>
          </div>
          <div
            className={
              name == "auth"
                ? "nav-step-container marked"
                : "nav-step-container"
            }
          >
            <div
              className={
                name == "auth" ? "nav-circle active-grow" : "nav-circle"
              }
            >
              <span>4</span>
            </div>
            <br />
            <span className="step-details">
              {name == "auth" ? "Log In" : ""}
            </span>
          </div>
          <div
            className={
              name == "prepare"
                ? "nav-step-container marked"
                : "nav-step-container"
            }
          >
            <div
              className={
                name == "prepare" ? "nav-circle active-grow" : "nav-circle"
              }
            >
              <span>5</span>
            </div>
            <br />
            <span className="step-details">
              {name == "prepare" ? "Prepare Documents" : ""}
            </span>
          </div>
          <div
            className={
              name == "insured-details"
                ? "nav-step-container marked"
                : "nav-step-container"
            }
          >
            <div
              className={
                name == "insured-details"
                  ? "nav-circle active-grow"
                  : "nav-circle"
              }
            >
              <span>6</span>
            </div>
            <br />
            <span className="step-details">
              {name == "insured-details" ? "Details of Insured" : ""}
            </span>
          </div>
          <div
            className={
              name == "beneficiary" || name == "group-details"
                ? "nav-step-container marked"
                : "nav-step-container"
            }
          >
            <div
              className={
                name == "beneficiary" || name == "group-details"
                  ? "nav-circle active-grow"
                  : "nav-circle"
              }
            >
              <span>7</span>
            </div>
            <br />
            <span className="step-details">
              {name == "beneficiary" ? "Beneficiary Details" : ""}
              {name == "group-details" ? "Group Details" : ""}
            </span>
          </div>
          <div
            className={
              name == "summary"
                ? "nav-step-container marked"
                : "nav-step-container"
            }
          >
            <div
              className={
                name == "summary" ? "nav-circle active-grow" : "nav-circle"
              }
            >
              <span>8</span>
            </div>
            <br />
            <span className="step-details">
              {name == "summary" ? "Summary & Confirmation" : ""}
            </span>
          </div>
          <div
            className={
              name == "payment"
                ? "nav-step-container marked"
                : "nav-step-container"
            }
          >
            <div
              className={
                name == "payment" ? "nav-circle active-grow" : "nav-circle"
              }
            >
              <span>9</span>
            </div>
            <br />
            <span className="step-details">
              {name == "payment" ? "Payment" : ""}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Steps;
