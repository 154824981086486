import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as GrIcons from "react-icons/gr";
import "./medicalClaim.css";
const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const travelduqaIcon = require("../../../assets/images/travel-duga-logo.png");

function MedicalExpenseClaim({ setCurrentScreen }: any) {
  const navigate = useNavigate();
  const [sameCondtions, setSameCondtions] = useState<boolean>(false);
  const [isHospitalizedBefore, setIsHospitalizedBefore] =
    useState<boolean>(false);
  const [isValidE111, setValidE111] = useState<boolean>(false);
  useEffect(() => {
    setCurrentScreen("claim-medical");
    sessionStorage.setItem("claim-screens", "claim-medical");
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    // setBeneficiaryInfo((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
  };

  const saveDetails = () => {};
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={travelduqaIcon} alt="travelduqa icon" />
        </div>
        <div className="reg-title text-center mb-4">
          <span style={{ color: "#000000" }}>
            Please provide these details for your
          </span>{" "}
          <span className="span-title">Medical Expenses Claim...</span> 
        </div>
        <div className="cancellation-input">
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="dateOfIllness">
                  Date of injury/onset of illness
                </label>
                <br />
                <input
                  id="dateOfIllness"
                  name="dateOfIllness"
                  type="date"
                  onChange={handleInputChange}
                  // value={travelInfo.departureCountry}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="placeOfInjury">
                  Place of injury/illness{" "}
                </label>
                <br />
                <input
                  id="placeOfInjury"
                  name="placeOfInjury"
                  type="text"
                  onChange={handleInputChange}
                  // value={travelInfo.placeOfInjury}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="detailOfIllness"
                >
                  Details of injury/illness
                </label>
                <br />
                <input
                  id="detailOfIllness"
                  name="detailOfIllness"
                  type="text"
                  onChange={handleInputChange}
                  // value={travelInfo.detailOfIllness}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="circumstancesOfIllness"
                >
                  Circumstances of accident (if applicable){" "}
                </label>
                <br />
                <input
                  id="circumstancesOfIllness"
                  name="circumstancesOfIllness"
                  type="text"
                  onChange={handleInputChange}
                  // value={travelInfo.circumstancesOfIllness}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="postal">
                Have you suffered from the same/similar condition before?{" "}
              </label>
              <br />
              <div className="countries d-flex mb-3">
                <button
                  className={
                    sameCondtions
                      ? "countries-selection multiple-country baggage-button isOption-active"
                      : "countries-selection multiple-country baggage-button"
                  }
                  onClick={() => setSameCondtions(true)}
                >
                  Yes
                </button>
                <button
                  className={
                    !sameCondtions
                      ? "countries-selection one-country baggage-button isOption-active"
                      : "countries-selection one-country baggage-button"
                  }
                  onClick={() => setSameCondtions(false)}
                >
                  No
                </button>
              </div>
            </motion.div>
          </div>
          <motion.div
            animate={{ scale: [-0.7, 1] }}
            transition={{ duration: 1 }}
          >
            <div className="expense-disclaimer">
              <div className="expense-disclaimer-name">PLEASE NOTE:</div>
              <div className="expense-disclaimer-content">
                Any charge made by a doctor for medical reports must be paid by
                the claimant
              </div>
            </div>
          </motion.div>

          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Have you been hospitalized?{" "}
                </label>
                <br />
                <div className="countries d-flex mb-3">
                  <button
                    className={
                      isHospitalizedBefore
                        ? "countries-selection multiple-country baggage-button isOption-active"
                        : "countries-selection multiple-country baggage-button"
                    }
                    onClick={() => setIsHospitalizedBefore(true)}
                  >
                    Yes
                  </button>
                  <button
                    className={
                      !isHospitalizedBefore
                        ? "countries-selection one-country baggage-button isOption-active"
                        : "countries-selection one-country baggage-button"
                    }
                    onClick={() => setIsHospitalizedBefore(false)}
                  >
                    No
                  </button>
                </div>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Were you in possession of a valid E111* form?{" "}
                </label>
                <br />
                <div className="countries d-flex mb-3">
                  <button
                    className={
                      isValidE111
                        ? "countries-selection multiple-country baggage-button isOption-active"
                        : "countries-selection multiple-country baggage-button"
                    }
                    onClick={() => setValidE111(true)}
                  >
                    Yes
                  </button>
                  <button
                    className={
                      !isValidE111
                        ? "countries-selection one-country baggage-button isOption-active"
                        : "countries-selection one-country baggage-button"
                    }
                    onClick={() => setValidE111(false)}
                  >
                    No
                  </button>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="expensesClaimed"
                >
                  Expenses claimed
                </label>
                <br />
                <input
                  id="expensesClaimed"
                  name="expensesClaimed"
                  type="number"
                  onChange={handleInputChange}
                  // value={travelInfo.expensesClaimed}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="insuranceNumber"
                >
                  Please provide your National Insurance Number
                </label>
                <br />
                <input
                  id="insuranceNumber"
                  name="insuranceNumber"
                  type="text"
                  onChange={handleInputChange}
                  // value={travelInfo.insuranceNumber}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="amountClaimed">
                  Amount claimed
                </label>
                <br />
                <input
                  id="amountClaimed"
                  name="amountClaimed"
                  type="number"
                  onChange={handleInputChange}
                  // value={travelInfo.amountClaimed}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.setItem("claim-screens", "claim-options");
                setCurrentScreen("claim-options");
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <div className="mt-5" onClick={() => saveDetails()}>
            <button className="pointer-btn next-btn">
              {" "}
              <span>
                Proceed{" "}
                <span className="GrIcon ms-3">
                  <GrIcons.GrFormNextLink />
                </span>
              </span>{" "}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default MedicalExpenseClaim;
