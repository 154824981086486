import { motion } from "framer-motion";
import React, { useState } from "react";
import { useWizard } from "react-use-wizard";
import * as GrIcons from "react-icons/gr";
import { toast } from "react-toastify";
import { makePostRequest } from "../../../services/apiHandler";
import { stepActions } from "../../../store/Steps";
import { useDispatch } from "react-redux";
import Loader from "../../../shared/loader/Loader";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

function BeneficiaryInformation({ setCurrentScreen }: any) {
  const { previousStep, nextStep, goToStep } = useWizard();
  const dispatch = useDispatch();
  const u: any = sessionStorage.getItem("userInfo");
  const uDocs: any = sessionStorage.getItem("userDocs");

  const SKEY = sessionStorage.getItem("SKEY") || "";

  const email = sessionStorage.getItem("email") || "";
  const q: any = sessionStorage.getItem("quote");

  const userInfo = JSON.parse(u);
  const quote = JSON.parse(q);

  const bInfo: any = sessionStorage.getItem("beneficiaryInfo");
  const userDocs = JSON.parse(uDocs);
  const beneficiaryInfo = JSON.parse(bInfo);

  const [loading, setLoading] = useState(false);
  const travel_type = sessionStorage.getItem("travel_type")!;

  const getQuestionsPayloadBody = () => {
    let body: any = {
      moreQuestions: {
        client: {
          email: email,
          ...userInfo,
          ...beneficiaryInfo,
          ...userDocs,
        },
      },
    };
    if (travel_type == "Group") {
      let g = sessionStorage.getItem("group_members")!;
      const gMembers: any[] = JSON.parse(g);
      if (gMembers.length > 0) {
        body.moreQuestions.dependents = [...gMembers];
      }
    }
    return body;
  };
  const saveDetails = () => {
    saveQuestions();
  };

  const saveQuestions = async () => {
    setLoading(true);
    await makePostRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/savequestions`,
      {
        ...getQuestionsPayloadBody(),
      },
      {
        SKEY: SKEY,
        ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    )
      .then((result: IApiResponse) => {
        if (result.Status === 200) {
          let que = result.Payload;
          sessionStorage.setItem("questions_id", que._id);
          sessionStorage.setItem("questions", JSON.stringify(que));
          saveQuote(que._id);
        } else if (result.Status === 541 || result.Status === 543) {
          toast.warning("Session expired please login again!");
          goToStep(3);
        }
        if (result.Status === 501 || result.Status === 401) {
          toast.warning("Session went wrong please try again!");
          goToStep(3);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.warning(error);
        setLoading(false);
      });
  };

  const saveQuote = async (id: any) => {
    let data = {
      ...quote,
      questions_id: id,
      ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
    };

    const result: IApiResponse = await makePostRequest(
      `${process.env.REACT_APP_TRAVEL_API_URL}/savequote`,
      {
        ...data,
      },
      {
        SKEY: SKEY,
        ModuleID: `${process.env.REACT_APP_MODULE_ID}`,
        apiKey: `${process.env.REACT_APP_MODULE_ID}`,
      }
    );

    if (result.Status === 200) {
      setLoading(false);
      let quo = result.Payload;
      sessionStorage.setItem("quote_id", quo._id);
      sessionStorage.setItem("new_quote", JSON.stringify(quo));
      if (quo.amount < 10000) {
        dispatch(stepActions.onAddStep());
        sessionStorage.setItem("payment-screen", "payment-method");
        sessionStorage.removeItem("summary-confirmation-screen");
        nextStep();
      } else {
        dispatch(stepActions.onAddStep());
        sessionStorage.setItem("payment-screen", "payment-opt");
        sessionStorage.removeItem("summary-confirmation-screen");
        nextStep();
      }
    }

    if (result.Status === 543 || result.Status === 401) {
      setLoading(false);
      toast.warning("Session expired please login again!");
      goToStep(3);
    }

    if (result.Status === 501) {
      setLoading(false);
      toast.error("Something went wrong! Try again.");
    }

    setLoading(false);
  };
  return (
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
      className="steps-conatiner2"
    >
      <div className="personal-information">
        <div className="reg-title text-center">
          Your <span style={{ color: "#E55C3E" }}>beneficiary details...</span>
        </div>
        <br />
        <div className="confirmation-container">
          <div className="confirmation-wapper">
            <div className="conf-sect-group mb-2">
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Full Name</span>
                <span className="conf-keys">{beneficiaryInfo?.nokName}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Relationship</span>
                <span className="conf-keys">
                  {beneficiaryInfo?.nokRelationship}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="conf-keys">Phone Number</span>
                <span className="conf-keys">{beneficiaryInfo?.nokPhoneNo}</span>
              </div>
            </div>

            <div className="mt-5 mb-5"></div>
          </div>
          <div className="d-flex justify-content-between align-items-center ms-3 me-2 mt-5">
            <div className="previous">
              <span
                onClick={(e) => {
                  sessionStorage.setItem(
                    "summary-confirmation-screen",
                    "personal-information"
                  );
                  setCurrentScreen("personal-information");
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            {loading && <Loader />}
            {!loading && (
              <div onClick={() => saveDetails()}>
                <button className="next-btn">
                  {" "}
                  <span>
                    Proceed{" "}
                    <span className="GrIcon ms-3">
                      <GrIcons.GrFormNextLink />
                    </span>
                  </span>{" "}
                </button>
              </div>
            )}
          </div>
        </div>
        <br />
        <br />
      </div>
    </motion.div>
  );
}

export default BeneficiaryInformation;
