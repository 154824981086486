import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as GrIcons from "react-icons/gr";
import "./cancellation.css";
import { toast } from "react-toastify";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const travelduqaIcon = require("../../../assets/images/travel-duga-logo.png");

function Cancellation({ setCurrentScreen }: any) {
  const navigate = useNavigate();
  const [cancellationInfo, setCancellationInfo]=useState({
    cancellationReason: "",
    initialBookingDate: "",
    totalCost: 0,
    salesDate: "",
    claimPrice: 0,
    cancellationDate: "",
    totalRefund: 0
  })
  useEffect(() => {
    setCurrentScreen("claim-cancellation");
    sessionStorage.setItem("claim-screens", "claim-cancellation");
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setCancellationInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveDetails = () => {

    if(!cancellationInfo.cancellationReason){
      document.getElementById("cancellationReason")?.focus
      toast.warning("Enter cancellation reason!")
      return
    }
    if(!cancellationInfo.initialBookingDate){
      document.getElementById("initialBookingDate")?.focus
      toast.warning("Enter initial booking date!")
      return
    }
    if(!cancellationInfo.totalCost){
      document.getElementById("totalCost")?.focus
      toast.warning("Enter the total cost for the holiday!")
      return
    }
    if(!cancellationInfo.salesDate){
      document.getElementById("salesDate")?.focus
      toast.warning("Enter insurance purchase date!")
      return
    }
    if(!cancellationInfo.claimPrice){
      document.getElementById("claimPrice")?.focus
      toast.warning("Enter the total claim price!")
      return
    }
    if(!cancellationInfo.cancellationDate){
      document.getElementById("cancellationDate")?.focus
      toast.warning("Enter the cancellation date!")
      return
    }
    if(!cancellationInfo.totalRefund){
      document.getElementById("totalRefund")?.focus
      toast.warning("Enter your refund amount!")
      return
    }
    
    setCurrentScreen("claim-documents");
    sessionStorage.setItem("claims-back", "claim-cancellation");
    sessionStorage.setItem("claim-screens", "claim-documents");
    sessionStorage.setItem(
      "claimInfo",
      JSON.stringify(cancellationInfo)
    );
    sessionStorage.setItem("claim_type", "Cancellation Claim")
  };

  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={travelduqaIcon} alt="travelduqa icon" />
        </div>
        <div className="reg-title text-center mb-4">
          <span style={{ color: "#000000" }}>
            Please provide these details for your
          </span>{" "}
        <span className="span-title">Cancellation Claim...</span>  
        </div>
        <div className="cancellation-input">
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="departureCountry"
                >
                  What is the reason for cancellation or curtailment?
                </label>
                <br />
                <input
                  id="cancellationReason"
                  name="cancellationReason"
                  type="text"
                  onChange={handleInputChange}
                  value={cancellationInfo.cancellationReason}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="initialBookingDate">
                  When was the trip originally booked?{" "}
                </label>
                <br />
                <input
                  id="initialBookingDate"
                  name="initialBookingDate"
                  type="date"
                  onChange={handleInputChange}
                  value={cancellationInfo.initialBookingDate}
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="totalCost"
                >
                  What was the total cost of holiday?
                </label>
                <br />
                <input
                  id="totalCost"
                  name="totalCost"
                  type="number"
                  onChange={handleInputChange}
                  value={cancellationInfo.totalCost}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="salesDate">
                  When was the insurance purchased?{" "}
                </label>
                <br />
                <input
                  id="salesDate"
                  name="salesDate"
                  type="date"
                  onChange={handleInputChange}
                  value={cancellationInfo.salesDate}
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label
                  className="text-left-label mb-2"
                  htmlFor="claimPrice"
                >
                  How much was claimed?
                </label>
                <br />
                <input
                  id="claimPrice"
                  name="claimPrice"
                  type="text"
                  onChange={handleInputChange}
                  value={cancellationInfo.claimPrice}
                  placeholder="Type here"
                ></input>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="cancellationDate">
                  When was the trip cancelled?{" "}
                </label>
                <br />
                <input
                  id="cancellationDate"
                  name="cancellationDate"
                  type="date"
                  onChange={handleInputChange}
                  value={cancellationInfo.cancellationDate}
                ></input>
              </motion.div>
            </div>
          </div>
          <div className="ky-inputs-inner-container">
          <div className="ky-input-wrapper">
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container ky-input-container-claim mt-4"
            >
              <label
                className="text-left-label mb-2"
                htmlFor="totalRefund"
              >
                How much was refunded?
              </label>
              <br />
              <input
                id="totalRefund"
                name="totalRefund"
                type="number"
                onChange={handleInputChange}
                value={cancellationInfo.totalRefund}
                placeholder="Type here"
              ></input>
            </motion.div>
          </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.setItem("claim-screens", "claim-options");
                setCurrentScreen("claim-options");
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <div className="mt-5" onClick={() => saveDetails()}>
            <button className="pointer-btn next-btn">
              {" "}
              <span>
                Proceed{" "}
                <span className="GrIcon ms-3">
                  <GrIcons.GrFormNextLink />
                </span>
              </span>{" "}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Cancellation;
