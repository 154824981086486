import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import * as GrIcons from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const travelduqaIcon = require("../../../assets/images/travel-duga-logo.png");

function liabilityClaim({ setCurrentScreen }: any) {
  const navigate = useNavigate();
  const [liabilityInfo, setLiabilityInfo] = useState({
    startDate: "",
    endDate: "",
    apartmentName: "",
    apartmentPoBox: "",
    apartmentPostalAddress: "",
    details: "",
  });
  useEffect(() => {
    setCurrentScreen("claim-liability");
    sessionStorage.setItem("claim-screens", "claim-liability");
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setLiabilityInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveDetails = () => {
    if (!liabilityInfo.startDate) {
      document.getElementById("startDate")?.focus;
      toast.warning("Enter start date!");
      return;
    }
    if (!liabilityInfo.endDate) {
      document.getElementById("endDate")?.focus;
      toast.warning("Enter end date!");
      return;
    }
    if (!liabilityInfo.apartmentName) {
      document.getElementById("apartmentName")?.focus;
      toast.warning("Enter apartment name!");
      return;
    }
    if (!liabilityInfo.apartmentPoBox) {
      document.getElementById("apartmentPoBox")?.focus;
      toast.warning("Enter apartment P.O Box!");
      return;
    }
    if (!liabilityInfo.apartmentPostalAddress) {
      document.getElementById("startDate")?.focus;
      toast.warning("Enter apartment postal address!");
      return;
    }
    if (!liabilityInfo.details) {
      document.getElementById("details")?.focus;
      toast.warning("Enter full details!");
      return;
    }
    setCurrentScreen("claim-documents");
    sessionStorage.setItem("claims-back", "claim-liability");
    sessionStorage.setItem("claim-screens", "claim-documents");
    sessionStorage.setItem("claimInfo", JSON.stringify(liabilityInfo));
    sessionStorage.setItem("claim_type", "Personal Liability Claim");
  };
  return (
    <div className="container">
      <motion.div
        variants={variants}
        initial="enter"
        animate="center"
        exit={{ opacity: 0 }}
        className="steps-conatiner2"
      >
        <div
          className="image text-center mt-5 mb-3"
          onClick={() => navigate("/")}
        >
          <img src={travelduqaIcon} alt="travelduqa icon" />
        </div>
        <div className="reg-title text-center mb-4">
          <span>
            Please provide these details for your
          </span>{" "}
          <span className="span-title">Personal Liability Claim...</span>   
        </div>
        <div className="cancellation-input">
          <div className="ky-inputs-inner-container">
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container ky-input-container-claim mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="startDate">
                  What was the date & time of incident?
                </label>
                <br />
                <div className="postal-area-container">
                  <input
                    id="startDate"
                    name="startDate"
                    type="date"
                    onChange={handleInputChange}
                    value={liabilityInfo.startDate}
                  ></input>

                  <input
                    id="endDate"
                    name="endDate"
                    type="date"
                    onChange={handleInputChange}
                    value={liabilityInfo.endDate}
                  ></input>
                </div>
              </motion.div>
            </div>
            <div className="ky-input-wrapper">
              <motion.div
                animate={{ scale: [-0.7, 1] }}
                transition={{ duration: 1 }}
                className="ky-input-container mt-4"
              >
                <label className="text-left-label mb-2" htmlFor="postal">
                  Name and address of holiday apartment/hotel{" "}
                </label>
                <br />
                <div className="apartment-input" style={{ width: "100%" }}>
                  <input
                    id="apartmentName"
                    name="apartmentName"
                    type="text"
                    onChange={handleInputChange}
                    value={liabilityInfo.apartmentName}
                    placeholder="Name"
                  ></input>
                </div>
                <div className="postal-area-container mt-2">
                  <input
                    id="apartmentPoBox"
                    name="apartmentPoBox"
                    type="text"
                    onChange={handleInputChange}
                    value={liabilityInfo.apartmentPoBox}
                    placeholder="P.O.Box"
                  ></input>

                  <input
                    id="apartmentPostalAddress"
                    name="apartmentPostalAddress"
                    type="text"
                    onChange={handleInputChange}
                    value={liabilityInfo.apartmentPostalAddress}
                    placeholder="Postal Code"
                  ></input>
                </div>
              </motion.div>
            </div>
          </div>
          <div className="ky-input-wrapper mb-5" style={{ width: "100%" }}>
            <motion.div
              animate={{ scale: [-0.7, 1] }}
              transition={{ duration: 1 }}
              className="ky-input-container ky-input-container-claim mt-4"
            >
              <label className="text-left-label mb-2" htmlFor="details">
                Please provide full details of incident
              </label>
              <br />
              <input
                id="details"
                name="details"
                type="text"
                onChange={handleInputChange}
                value={liabilityInfo.details}
                placeholder="Type here"
              ></input>
            </motion.div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="previous">
            <span
              onClick={(e) => {
                sessionStorage.setItem("claim-screens", "claim-options");
                setCurrentScreen("claim-options");
              }}
            >
              <span className="GrIcon me-2">
                <GrIcons.GrFormPreviousLink />
              </span>{" "}
              <span className="previous">Previous</span>
            </span>
          </div>
          <div className="mt-5" onClick={() => saveDetails()}>
            <button className="pointer-btn next-btn">
              {" "}
              <span>
                Proceed{" "}
                <span className="GrIcon ms-3">
                  <GrIcons.GrFormNextLink />
                </span>
              </span>{" "}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default liabilityClaim;
