import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as GrIcons from "react-icons/gr";
import { toast } from 'react-toastify';

const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };
  
  const travelduqaIcon = require("../../../assets/images/travel-duga-logo.png");

function CurtailmentClaim({setCurrentScreen}:any) {
    const navigate = useNavigate();
    const [curtailmentInfo, setCurtailmentInfo] = useState({
      initialTransportMethod: "",
      initialDate: "",
      returnDate: "",
      salesDate: "",
      originalBookingDate: "",
      additionalExpense: 0
    })
    useEffect(() => {
      setCurrentScreen("claim-curtailment");
      sessionStorage.setItem("claim-screens", "claim-curtailment");
    });
  
    const handleInputChange = (event: any) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
  
      setCurtailmentInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const saveDetails = () => {
      if(!curtailmentInfo.initialTransportMethod){
        document.getElementById("initialTransportMethod")?.focus
        toast.warning("Enter initial transport method!")
        return
      }
      if(!curtailmentInfo.originalBookingDate){
        document.getElementById("originalBookingDate")?.focus
        toast.warning("Enter original booking date!")
        return
      }
      // if(!curtailmentInfo.additionalExpense){
      //   document.getElementById("originalBookingDate")?.focus
      //   toast.warning("Enter additional expense booking date!")
      //   return
      // }
      if(!curtailmentInfo.initialDate){
        document.getElementById("initialDate")?.focus
        toast.warning("When was the incident that caused curtailment!")
        return
      }
      if(!curtailmentInfo.returnDate){
        document.getElementById("originalBookingDate")?.focus
        toast.warning("Enter the actual return date!")
        return
      }
      if(!curtailmentInfo.salesDate){
        document.getElementById("salesDate")?.focus
        toast.warning("Enter sales date!")
        return
      }
      setCurrentScreen("claim-documents");
      sessionStorage.setItem("claims-back", "claim-curtailment");
      sessionStorage.setItem("claim-screens", "claim-documents");
      sessionStorage.setItem(
        "claimInfo",
        JSON.stringify(curtailmentInfo)
      );
      sessionStorage.setItem("claim_type", "Curtailment Claim")
    };
  
    return (
      <div className="container">
        <motion.div
          variants={variants}
          initial="enter"
          animate="center"
          exit={{ opacity: 0 }}
          className="steps-conatiner2"
        >
          <div
            className="image text-center mt-5 mb-3"
            onClick={() => navigate("/")}
          >
            <img src={travelduqaIcon} alt="travelduqa icon" />
          </div>
          <div className="reg-title text-center mb-4">
            <span>
              Please provide these details for your
            </span>{" "}
            <span className="span-title">Trip Curtailment Claim...</span>  
          </div>
          <div className="cancellation-input">
            <div className="ky-inputs-inner-container">
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container ky-input-container-claim mt-4"
                >
                  <label
                    className="text-left-label mb-2"
                    htmlFor="initialTransportMethod"
                  >
                    What was the original transport method (Air/Ferry/Coach etc)
                  </label>
                  <br />
                  <input
                    id="initialTransportMethod"
                    name="initialTransportMethod"
                    type="text"
                    onChange={handleInputChange}
                    value={curtailmentInfo.initialTransportMethod}
                    placeholder="Type here"
                  ></input>
                </motion.div>
              </div>
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container ky-input-container-claim mt-4"
                >
                  <label className="text-left-label mb-2" htmlFor="originalBookingDate">
                    When was the trip originally booked?{" "}
                  </label>
                  <br />
                  <input
                    id="originalBookingDate"
                    name="originalBookingDate"
                    type="date"
                    onChange={handleInputChange}
                    value={curtailmentInfo.originalBookingDate}
                  ></input>
                </motion.div>
              </div>
            </div>
            <div className="ky-inputs-inner-container">
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container ky-input-container-claim mt-4"
                >
                  <label
                    className="text-left-label mb-2"
                    htmlFor="additionalExpense"
                  >
                    How much was claimed for additional expenses?
                  </label>
                  <br />
                  <input
                    id="additionalExpense"
                    name="additionalExpense"
                    type="number"
                    onChange={handleInputChange}
                    value={curtailmentInfo.additionalExpense}
                    placeholder="Type here"
                  ></input>
                </motion.div>
              </div>
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container ky-input-container-claim mt-4"
                >
                  <label className="text-left-label mb-2" htmlFor="initialDate">
                  When was the incident that caused curtailment?{" "}
                  </label>
                  <br />
                  <input
                    id="initialDate"
                    name="initialDate"
                    type="date"
                    onChange={handleInputChange}
                    value={curtailmentInfo.initialDate}
                  ></input>
                </motion.div>
              </div>
            </div>
            <div className="ky-inputs-inner-container">
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container ky-input-container-claim mt-4"
                >
                  <label
                    className="text-left-label mb-2"
                    htmlFor="returnDate"
                  >
                    When was the actual return date?
                  </label>
                  <br />
                  <input
                    id="returnDate"
                    name="returnDate"
                    type="text"
                    onChange={handleInputChange}
                    value={curtailmentInfo.returnDate}
                    placeholder="Type here"
                  ></input>
                </motion.div>
              </div>
              <div className="ky-input-wrapper">
                <motion.div
                  animate={{ scale: [-0.7, 1] }}
                  transition={{ duration: 1 }}
                  className="ky-input-container ky-input-container-claim mt-4"
                >
                  <label className="text-left-label mb-2" htmlFor="salesDate">
                  When was the insurance purchased?{" "}
                  </label>
                  <br />
                  <input
                    id="salesDate"
                    name="salesDate"
                    type="date"
                    onChange={handleInputChange}
                    value={curtailmentInfo.salesDate}
                  ></input>
                </motion.div>
              </div>
            </div>
          </div>
  
          <div className="d-flex justify-content-between align-items-center">
            <div className="previous">
              <span
                onClick={(e) => {
                  sessionStorage.setItem("claim-screens", "claim-options");
                  setCurrentScreen("claim-options");
                }}
              >
                <span className="GrIcon me-2">
                  <GrIcons.GrFormPreviousLink />
                </span>{" "}
                <span className="previous">Previous</span>
              </span>
            </div>
            <div className="mt-5" onClick={() => saveDetails()}>
              <button className="pointer-btn next-btn">
                {" "}
                <span>
                  Proceed{" "}
                  <span className="GrIcon ms-3">
                    <GrIcons.GrFormNextLink />
                  </span>
                </span>{" "}
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    );
}

export default CurtailmentClaim