import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as GrIcons from "react-icons/gr";
import { toast } from 'react-toastify';

const variants = {
    enter: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
  };
  
  const travelduqaIcon = require("../../../assets/images/travel-duga-logo.png");

function DepartureClaim({setCurrentScreen}:any) {
    const navigate = useNavigate();
    const [departureInfo, setDepartureInfo] = useState({
      reason: "",
      startDate: "",
      endDate: "",
      departurePoint: "",
      rejoinderMethod: "",
      originalTransportMethod: "",
      claimAmount: 0
    })
    useEffect(() => {
      setCurrentScreen("claim-departure");
      sessionStorage.setItem("claim-screens", "claim-departure");
    });
  
    const handleInputChange = (event: any) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
  
      setDepartureInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const saveDetails = () => {
      if(!departureInfo.reason){
        document.getElementById("reason")?.focus
        toast.warning("What’s the reason for delay/missed departure?")
        return
      }
      if(!departureInfo.startDate){
        document.getElementById("startDate")?.focus
        toast.warning("Enter actual start date!")
        return
      }
      if(!departureInfo.endDate){
        document.getElementById("endDate")?.focus
        toast.warning("Enter actual end date!")
        return
      }
      if(!departureInfo.departurePoint){
        document.getElementById("departurePoint")?.focus
        toast.warning("Enter the departure point!")
        return
      }
      if(!departureInfo.rejoinderMethod){
        document.getElementById("rejoinderMethod")?.focus
        toast.warning("What method was employed to rejoin trip?")
        return
      }
      if(!departureInfo.originalTransportMethod){
        document.getElementById("originalTransportMethod")?.focus
        toast.warning("Enter the original transport method!")
        return
      }
      if(!departureInfo.claimAmount){
        document.getElementById("claimAmount")?.focus
        toast.warning("Enter total claim amount!")
        return
      }
      setCurrentScreen("claim-documents");
      sessionStorage.setItem("claims-back", "claim-departure");
      sessionStorage.setItem("claim-screens", "claim-documents");
      sessionStorage.setItem(
        "claimInfo",
        JSON.stringify(departureInfo)
      );
      sessionStorage.setItem("claim_type", "Missed Departure Claim")
    };
  return (
    <div className="container">
    <motion.div
      variants={variants}
      initial="enter"
      animate="center"
      exit={{ opacity: 0 }}
      className="steps-conatiner2"
    >
      <div
        className="image text-center mt-5 mb-3"
        onClick={() => navigate("/")}
      >
        <img src={travelduqaIcon} alt="travelduqa icon" />
      </div>
      <div className="reg-title text-center mb-4">
        <span style={{ color: "#000000" }}>
          Please provide these details for your
        </span>{" "}
        <span className="span-title">Missed Departure Claim...</span> 
      </div>
      <div className="cancellation-input">
      <div className="ky-inputs-inner-container">
        <div className="ky-input-wrapper">
          <motion.div
            animate={{ scale: [-0.7, 1] }}
            transition={{ duration: 1 }}
            className="ky-input-container ky-input-container-claim mt-4"
          >
            <label className="text-left-label mb-2" htmlFor="reason">
            What’s the reason for delay/missed departure?{" "}
            </label>
            <br />
            <input
              id="reason"
              name="reason"
              type="text"
              onChange={handleInputChange}
              value={departureInfo.reason}
              placeholder="Type here"
            ></input>
          </motion.div>
        </div>
        <div className="ky-input-wrapper">
          <motion.div
            animate={{ scale: [-0.7, 1] }}
            transition={{ duration: 1 }}
            className="ky-input-container ky-input-container-claim mt-4"
          >
            <label className="text-left-label mb-2" htmlFor="postal">
            What was the actual date & time of departure?
            </label>
            <br />
            <div className="postal-area-container">
              <input
                id="startDate"
                name="startDate"
                type="date"
                onChange={handleInputChange}
                value={departureInfo.startDate}
              ></input>

              <input
                id="endDate"
                name="endDate"
                type="date"
                onChange={handleInputChange}
                value={departureInfo.endDate}
              ></input>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="ky-inputs-inner-container w-100">
       
      <div className="ky-input-wrapper">
          <motion.div
            animate={{ scale: [-0.7, 1] }}
            transition={{ duration: 1 }}
            className="ky-input-container ky-input-container-claim mt-4"
          >
            <label
              className="text-left-label mb-2"
              htmlFor="departurePoint"
            >
              Where was the point of departure?
            </label>
            <br />
            <input
              id="departurePoint"
              name="departurePoint"
              type="text"
              onChange={handleInputChange}
              value={departureInfo.departurePoint}
              placeholder="Type here"
            ></input>
          </motion.div>
        </div>

        <div className="ky-input-wrapper">
          <motion.div
            animate={{ scale: [-0.7, 1] }}
            transition={{ duration: 1 }}
            className="ky-input-container ky-input-container-claim mt-4"
          >
            <label
              className="text-left-label mb-2"
              htmlFor="rejoinderMethod"
            >
              What method was employed to rejoin trip?
            </label>
            <br />
            <div className="postal-area-container">
              <input
                id="rejoinderMethod"
                name="rejoinderMethod"
                type="text"
                onChange={handleInputChange}
                value={departureInfo.rejoinderMethod}
                placeholder="Type here"
              ></input>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="ky-inputs-inner-container w-100">
       
      <div className="ky-input-wrapper">
          <motion.div
            animate={{ scale: [-0.7, 1] }}
            transition={{ duration: 1 }}
            className="ky-input-container ky-input-container-claim mt-4"
          >
            <label
              className="text-left-label mb-2"
              htmlFor="originalTransportMethod"
            >
              What was the original transport method (Air/Ferry/Coach etc)
            </label>
            <br />
            <input
              id="originalTransportMethod"
              name="originalTransportMethod"
              type="text"
              onChange={handleInputChange}
              value={departureInfo.originalTransportMethod}
            ></input>
          </motion.div>
        </div>

        <div className="ky-input-wrapper">
          <motion.div
            animate={{ scale: [-0.7, 1] }}
            transition={{ duration: 1 }}
            className="ky-input-container ky-input-container-claim mt-4"
          >
            <label
              className="text-left-label mb-2"
              htmlFor="claimAmount"
            >
              How much was claimed?
            </label>
            <br />
            <div className="postal-area-container">
              <input
                id="claimAmount"
                name="claimAmount"
                type="number"
                onChange={handleInputChange}
                value={departureInfo.claimAmount}
                placeholder="Type here"
              ></input>
            </div>
          </motion.div>
        </div>
      </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="previous">
          <span
            onClick={(e) => {
                sessionStorage.setItem("claim-screens", "claim-options");
                setCurrentScreen("claim-options");
            }}
          >
            <span className="GrIcon me-2">
              <GrIcons.GrFormPreviousLink />
            </span>{" "}
            <span className="previous">Previous</span>
          </span>
        </div>
        <div className="mt-5" onClick={() => saveDetails()}>
          <button className="pointer-btn next-btn">
            {" "}
            <span>
              Proceed{" "}
              <span className="GrIcon ms-3">
                <GrIcons.GrFormNextLink />
              </span>
            </span>{" "}
          </button>
        </div>
      </div>
    </motion.div>
  </div>
  )
}

export default DepartureClaim